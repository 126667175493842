import React, {useEffect, useState} from 'react'
import { Link, useLocation } from 'react-router-dom'

import axios from 'axios';
import { API_IMAGES, API_URL, config } from '../components/Include';
function Sidebar(props:any) {


   

  const menu = [
    {
      "menuName": "Dashboard",
      "description": "Display website usage statistics",
      "menuIcon": "fa fa-university",
      "isActive":'Yes',
      "menuUrl": "/dashboard",
      "order": 1,
      "ID": "aw4jkqzjw",
      "submenu": []
    },
    {
      "menuName": "Category",
      "description": "Display News cageory",
      "menuIcon": "fa fa-cubes",
      "isActive":'Yes',
      "menuUrl": "/category",
      "order": 2,
      "ID": "aw4sjkdqzjw",
      "submenu": []
    },
    {
      "menuName": "Staff",
      "description": " Student attendance",
      "menuIcon": "fa  fa-users",
      "isActive":'Yes',
      "menuUrl": "",
      "order": "6",
      "ID": "6uabdn9wm",
      "submenu": [
        
        {
          "subName": "Add New Staff",
          "subUrl": "/staff/add",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "fbrriuz2b",
          "subsubmenu": []
        },
        {
          "subName": "View All Staff",
          "subUrl": "/staff",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "kov9cnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Staff Roles",
          "subUrl": "/staff/role",
          "subDescription": "",
          "isActive":'No',
          "ID": "kov9cnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Staff Logs",
          "subUrl": "/staff/logs",
          "subDescription": "",
          "isActive":'No',
          "ID": "kov9cefnxvp",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "News",
      "description": "General Settings",
      "menuIcon": "fa fa-comments",
      "status": true,
      "menuUrl": "",
      "order": "17",
      "ID": "qb33ms9l2",
      "submenu": [
        {
          "subName": "Create News",
          "subUrl": "/news/add",
          "subDescription": "",
          "status": true,
          "ID": "ihj82q2cd",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        
        {
          "subName": "View All News",
          "subUrl": "/news",
          "subDescription": "",
          "status": true,
          "ID": "bck7qf4rd",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Comments",
          "subUrl": "",
          "subDescription": "",
          "status": true,
          "ID": "qs0qrf31u",
          "subsubmenu": [
            {
              "subsubName": "All Comments",
              "status": true,
              "subsubUrl": "/general/comments",
              "subsubDescription": "",
              "ID": "opubssssv6a",
              "isActive": "Yes"
            },
            {
              "subsubName": "Approve Comments",
              "status": true,
              "subsubUrl": "/general/comment/approve",
              "subsubDescription": "",
              "ID": "ppbswea",
              "isActive": "Yes"
            },
          ],
          "isActive": "No"
        },
        {
          "subName": "Collections",
          "subUrl": "/collections",
          "subDescription": "",
          "status": true,
          "ID": "bck7qf4rd",
          "subsubmenu": [],
          "isActive": "Yes"
        },
        {
          "subName": "Tags",
          "subUrl": "/news/tags",
          "subDescription": "",
          "status": true,
          "ID": "bck7qf4rd",
          "subsubmenu": [],
          "isActive": "Yes"
        }
      ],
      "isActive": "Yes"
    },

    {
      "menuName": "General",
      "description": " System Settings",
      "menuIcon": "fa  fa-cogs",
      "isActive":'Yes',
      "menuUrl": "",
      "order": "6",
      "ID": "6uabdn9ccwm",
      "submenu": [
        
        {
          "subName": "Basic Info",
          "subUrl": "/general/basic",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "fbrhcriuz2b",
          "subsubmenu": []
        },
        {
          "subName": "Subscribers",
          "subUrl": "/general/subscribers",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "kghov9cnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Advertisement",
          "subUrl": "/staff/role",
          "subDescription": "",
          "isActive":'No',
          "ID": "kovfc9cnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Polls",
          "subUrl": "",
          "subDescription": "",
          "status": true,
          "ID": "qdc50qrf31u",
          "subsubmenu": [
            {
              "subsubName": "All Polls",
              "status": true,
              "subsubUrl": "/general/polls",
              "subsubDescription": "",
              "ID": "opubs80qsv6a",
              "isActive": "Yes"
            },
            {
              "subsubName": "Create Polls",
              "status": true,
              "subsubUrl": "/general/poll/add",
              "subsubDescription": "",
              "ID": "ppbs80qsv6a",
              "isActive": "Yes"
            },
          ],
          "isActive": "Yes"
        },
        {
          "subName": "Advertisement",
          "subUrl": "/general/advert",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "fbriuz2b",
          "subsubmenu": []
        }
      ]
    },

    {
      "menuName": "Settings",
      "description": " Student attendance",
      "menuIcon": "fa  fa-cog",
      "isActive":'No',
      "menuUrl": "",
      "order": "6",
      "ID": "6uabftdn9wm",
      "submenu": [
        
        {
          "subName": "Message Template",
          "subUrl": "#",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "fbrriuz2b",
          "subsubmenu": []
        },
        {
          "subName": "Error Logs",
          "subUrl": "#",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "kov9gtcnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Sitemap",
          "subUrl": "#",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "kov9ftycnxvp",
          "subsubmenu": []
        },
        {
          "subName": "Maintenance",
          "subUrl": "#",
          "subDescription": "",
          "isActive":'Yes',
          "ID": "kov9cexvp",
          "subsubmenu": []
        }
      ]
    },
    {
      "menuName": "Documents",
      "description": "Upload documents",
      "menuIcon": "fa fa-file",
      "isActive":'Yes',
      "menuUrl": "/documents",
      "order": 2,
      "ID": "osjtdhop",
      "submenu": []
    }
  ];
  let location =  useLocation()
  const [isDisplay, setIsDisplay] = useState({ } as any);
    
     
  
      const [isDisplaySubmenu, setIsDisplaySubmenu] = useState({ [props.submenu]: true });
  
  
  const handleToggleDisplaySubmenu=(main:any, sub:any)=>{
     // handleToggleDisplay(main)
     if(sub!=='0'){
     
      setIsDisplay({[main]: true});
      setIsDisplaySubmenu({[sub]: !isDisplaySubmenu[sub]});
  }else{
      setIsDisplay({[main]: !isDisplay[main]})
  }
  }



  const [content, setContent] = useState([] as any);

  const fetchContent = async()=>{
     let url = API_URL+'/careless/general/basic/display/all'
       await  axios.get(url,  config).then((result:any)=>{
         if(Array.isArray(result.data.data)){
         setContent(result.data.data[0])
         }
         })
         .catch((error)=>{
          //ErrorModal('oops', error.message)
         })
       }


       useEffect(()=>{  
        fetchContent()   
      },[]); 

  return (  <div className="sticky" >
     
      <aside className="app-sidebar ps ps--active-y sidebar-scroll"> 
      
      <div className="app-sidebar__header"> 
      
      <a className="main-logo" href="/dashboard">
        <img src={API_IMAGES+'/logo.png'} className="desktop-logo desktop-logo-dark" alt="logo" /> 
        <img src={API_IMAGES+'/logo.png'}className="desktop-logo" alt="logo" /> 
        
        <img src={API_IMAGES+'/logo.png'} className="mobile-logo mobile-logo-dark" alt="logo" />
          <img src={API_IMAGES+'/logo.png'} className="mobile-logo" alt="logo" /> </a> 
          </div> 
          
          <div className="main-sidemenu"> 
          
       
          <div className="slide-left " id="slide-left"></div>

          
            <ul className="side-menu" id="side-menu" > 

            {menu.length!==0?menu.map((data:any, index:number)=>data.isActive==='Yes'?


<li className="slide" key={index} > 

          {data.submenu.length ===0? <Link onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')}  to={data.menuUrl} className={data.submenu.length ===0 && location.pathname===data.menuUrl?'side-menu__item active':
          
          'side-menu__item'    }  data-bs-toggle="slide" >
                  <i className={data.menuIcon}></i>
                     <span className="side-menu__label">{data.menuName}</span> </Link>: 


          <a  className={isDisplay[data.ID] ?'side-menu__item active':
          
          'side-menu__item'    } data-bs-toggle="slide" href="#" onClick={()=>handleToggleDisplaySubmenu(data.ID, '0')} > 
          <span className="side-menu__icon"> 
          <i className={data.menuIcon}></i> </span> 
          <span className="side-menu__label">{data.menuName}</span>
           <i className={isDisplay[data.ID]?'angle fa fa-angle-down':'angle fa fa-angle-right'}></i> 
           </a>} 

           {data.submenu.length !==0? <ul className={isDisplay[data.ID] && data.submenu.length!==0?"slide-menu open":"slide-menu"} style={{display:isDisplay[data.ID] && data.submenu.length!==0?'block':'none'}}> 


          <li className="panel sidetab-menu" > 
          
          <div className="panel-body tabs-menu-body p-0 border-0">
            <div className="tab-content"> 


            <div className="tab-pane active" id="side1" role="tabpanel"> 
            <ul className="sidemenu-list"> 

            {data.submenu.map((smenu:any, sid:number)=>smenu.isActive==='Yes'?
          
               <li key={sid} className={smenu.subsubmenu.length===0?"":"sub-slide is-expanded"} > 
               
               {smenu.subsubmenu.length===0?
               
               <Link onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)} to={smenu.subUrl} className={smenu.subsubmenu.length ===0 && location.pathname===smenu.subUrl?'slide-item active':'slide-item'} ><span> <i className='fa fa-ellipsis-h'></i> {smenu.subName}</span></Link>: 
               
               
               <a className="slide-item side-menu__item-sub" data-bs-toggle="sub-slide" href="#" onClick={()=>handleToggleDisplaySubmenu(data.ID, smenu.ID)}>
                <span className="sub-side-menu__label"><i className='fa fa-ellipsis-h'></i> {smenu.subName}</span>
                <i className={isDisplaySubmenu[smenu.ID]?'sub-angle fa fa-angle-down':'angle fa fa-angle-right'}></i></a>} 
                

                {smenu.subsubmenu.length!==0?  <ul className={isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length!==0?"sub-slide-menu open":"sub-slide-menu"}  style={{display:isDisplaySubmenu[smenu.ID] && smenu.subsubmenu.length!==0?'block':'none'}} > 


                {smenu.subsubmenu.map((subsub:any, k:number)=>subsub.isActive==='Yes'?
                <li key={k}><Link to={subsub.subsubUrl}  className={location.pathname===subsub.subsubUrl?'slide-item active':'slide-item'}> ... {subsub.subsubName}</Link></li>:'')}
                 

                 </ul>:''}

                  </li>:'')}

               </ul> 
               
            </div>  
            </div> </div> 
            </li> 
                
                
                </ul>:''} 
                
                
                </li>:''):''}



                
<li className="slide">
                  
                  <a className="side-menu__item" data-bs-toggle="slide" href="https://api.whatsapp.com/send?phone=2348032950881" target="_blank" rel='noopener noreferrer' >
                  <i className="fa fa-bug"></i>
                     <span className="side-menu__label">Suggest Features</span> </a> 
                     </li> 
                <li className="slide">
                  
                  <a className="side-menu__item" data-bs-toggle="slide" href="/logout">
                  <i className="fa fa-power-off"></i>
                     <span className="side-menu__label"> Log Out</span> </a> 
                     </li> 
     </ul> 
    
    </div> 
    
    
    
    
    
    </aside> 
    
    </div>
  )
}

export default Sidebar