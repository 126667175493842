
import { useState, ChangeEvent, FormEvent, useEffect} from 'react'
import axios from 'axios';
import Cookies from 'js-cookie'
import { API_IMAGES, API_URL, Token } from '../../components/Include';
import { Alerts } from '../../components/notify';

const Login =()=> {


  const [content, setContent] = useState([] as any);

  const fetchContent = async()=>{
     let url = API_URL+'/careless/general/basic/display/all'
       await  axios.get(url,  config).then((result:any)=>{

         if(Array.isArray(result.data.data)){
         setContent(result.data.data[0])
         }
         })
         .catch((error)=>{
          //ErrorModal('oops', error.message)
         })
       }

     

	const [notice, setNotice] = useState({
    isLoading : false,
    isDataFetching: false
});

const config = {
  headers: { 
      "Access-Control-Allow-Origin": "*",
      'content-type': 'multipart/form-data'
}
} 

const [user, setUser] = useState({
email:'',
password:'',
remember:'No'
})
const [errors, setErrors] = useState({
    email:'',
    password:''

});

const handleChange = (event:ChangeEvent<HTMLInputElement>)=>{
    let {name, value} = event.target;	
    if(name==='remember'){
        value= value==='Yes'?'No':'Yes'
    }	
   setUser({...user, [name] : value });
   setErrors({...errors, [name]:''});
}

const handleSubmit =(event: FormEvent<HTMLFormElement>)=>{
    event.preventDefault();

    let error = {...errors}; 
    let formIsValid = true;

let msg ='This field is required';


if(!user.email){
error.email =msg;
formIsValid = false;
} 
if(!user.password){
error.password =msg;
formIsValid = false;
} 

if(!navigator.onLine){
    Alerts('danger', 'No internet connection')
    formIsValid = false;
} 


if(!formIsValid){
setErrors(error) 
}

if(formIsValid) {

setNotice({...notice, isLoading: true}) 	
       
    const fd = new FormData();
    fd.append('email', user.email);
    fd.append('password', user.password);

       let url = API_URL+'/staff/login';
axios.post(url, fd, config)
.then(response =>{
        if(response.data.status === 200){
  
  //1000 * 60 * SESSION_IDEL_MINUTES
  var inSixtyMinutes:any = Date.now() + 419 * 60 * 1000;
  var inOneMinutes:any = Date.now() + 418 * 60 * 1000;
    var inOneHours = new Date(new Date().getTime() + 419 * 60 * 1000);
    
     localStorage.setItem('_expt', inSixtyMinutes)
    localStorage.setItem('_bexpt', inOneMinutes) 


    
  Cookies.set('thrucd', response.data.code, {expires: inOneHours })
  Cookies.set('thrgpcd', response.data.group_code, {expires: inOneHours })
  Cookies.set('thrtk', response.data.token, {expires: inOneHours })   
  Alerts('succes', 'You have successfully login ', 'Successful')
  
  let queryString = localStorage.getItem('refferer') 
  //var queryString = new URLSearchParams(window.location.search).get("refferer")
            if(queryString&&queryString!==null){
                window.open(queryString, '_self')
            }else{
                window.open('/dashboard', '_self')
            }   
  
  }else{
    Alerts('danger', response.data.message, 'Error')
  }    
})
.catch((error)=>{
  Alerts('danger', error.message, 'Error')
}).finally(()=>{
        setNotice({...notice, 
    isLoading: false
  }) 
    })   
}
}


useEffect(()=>{  

if(Token!==undefined){
window.open('/dashboard', '_self')
   
 }  
},[]); 


useEffect(()=>{  
  fetchContent()   
},[]); 
  return (
    <div className="page custom-pages "> 
    <div className="container"> <div className="row">
         <div className="col  mx-auto"> 
         
         <div className="text-center mb-4"> 
         
         <a className="" href="#"> 
         
         <img src={API_IMAGES+'/general/'+content.long_logo_white}  className="logo-dark w-200 " alt="logo" /> </a> 
         
         </div>
         
          <div className="row justify-content-center"> 
          
          <div className="col-md-8"> <div className="card-group mb-0"> <div className="card d-none d-lg-block br-e-0"> 
         <div className="card-body align-items-center"> 
         
         <div className="mt-5 ms-3 pt-4">
            
             <img src={API_IMAGES+'/general/'+content.favicon} className="mt-2 br-20" />
              </div> </div> </div> 
              
              <div className="card br-s-0 mb-0"> <div className="card-body"> <h2 className="text-medium text-dark ms-3 mt-3">Login</h2> <h6 className="fw-semibold text-muted mb-4 ms-3">Please sign in to continue.</h6> <div className="panel tabs-style7 mt-2"> <div className="panel-head"> 
              <ul className="nav nav-tabs" role="tablist"> 
              
              <li className="nav-item" role="presentation">
                <a className="nav-link fs-14 fw-semibold text-sm-center text-start ms-3 active" data-bs-toggle="tab" href="#signinTab1" aria-selected="true" role="tab">Sign In</a></li> 
                
                <li className="nav-item" role="presentation"><a className="nav-link fs-14 fw-semibold text-sm-center text-start" data-bs-toggle="tab" href="#signinTab2" aria-selected="false" role="tab" >Forgot Password?</a> </li>
                 </ul>
                  </div> 
                  
                  <div className="panel-body p-0"> <div className="tab-content mt-3"> 
                  
                  <div className="tab-pane active show" id="signinTab1" role="tabpanel"> 
                  
                  <form  onSubmit={handleSubmit} role={'presentation'} autoComplete='off' >
                  <div className="card-body pt-0"> 
                  
                  <p>Please Enter the Email address associated with your account </p>
                  
                  
                 


         <div className="form-group"> 
         <label  className="text-left form-label">Email Address</label>

         <input type="email" id="email_address" name="email" required autoComplete='off' role={'presentation'} value={user.email} onChange={handleChange} className={errors.email  ? 'form-control border-danger form-control-lg' : 'form-control form-control-lg'} /> 
         {errors.email&&
                        <div className="col-form-label has-danger">{errors.email&&errors.email} </div>}
          </div>
          
          

          <div className="form-group"> 
                  
                  <label className="form-label text-left" >Password</label> 
         
         
                  <input type="password" id="password" name="password" required autoComplete='off' role={'presentation'} value={user.password} onChange={handleChange} className={errors.password  ? 'form-control border-danger form-control-lg' : 'form-control form-control-lg'} /> 
                  {errors.password&&
                        <div className="col-form-label has-danger">{errors.password&&errors.password} </div>}
         </div> 


           <div className="checkbox text-left mb-2"> <div className="custom-checkbox custom-control"> 

           <input type="checkbox" data-checkboxes="mygroup" className="custom-control-input" value={user.remember} name="remember" checked={user.remember ==='Yes'?true:false}  onChange={handleChange} />
 <label  className="custom-control-label">Remember Me</label> </div> </div> 
         
         
         <div className=""> 

         {notice.isLoading ?	  <button type="button" disabled  className="btn btn-primary me-2 btn-block"><i className='fa fa-refresh fa-spin'></i> Please wait ...</button> :
				 <button type="submit"  className="btn btn-primary me-2 btn-block">Sign in</button>}

         </div> 
         
         
         </div> 
         </form>
         
         
         </div> 
         
         <div className="tab-pane ms-3" id="signinTab2" role="tabpanel"> 
         
         <div className="form-group"> 
         <label  className="text-left form-label">Email Address</label>

         <input type="email" id="email" name="email" required autoComplete='off' role={'presentation'} value={user.email} onChange={handleChange} className={errors.email  ? 'form-control border-danger form-control-lg' : 'form-control form-control-lg'} /> 
         {errors.email&&
                        <div className="col-form-label has-danger">{errors.email&&errors.email} </div>}
          </div>
         
         <div id="login-otp" className="justify-content-around mb-4"> 
         
         </div> <p>Note : An email that will include a link to change your password will be sent to you .</p> 
         
         <button type="button"  className="btn btn-primary me-2 btn-block">Proceed</button>
         
         </div> </div> </div> 
         
         </div> </div> </div> </div> 
         </div> </div> </div> </div> </div>
          </div>
  )
}

export default Login