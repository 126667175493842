import React from 'react'

import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from './Reports/Dashboard';
import Category from './Pages/Category';
import AddStaff from './Staff/AddStaff';
import UpdateStaff from './Staff/UpdateStaff';
import Staff from './Staff/Staff';
import AddNews from './News/AddNews';
import UpdateNews from './News/UpdateNews';
import News from './News/News';
import Collection from './News/Collection';
import Tags from './Pages/Tags';
import AddCollection from './News/AddCollection';
import Logout from './general/users/Logout';
import BasicInfo from './general/settings/BasicInfo';
import Subscribers from './general/Subscribers';
import Advert from './general/Advert';
import AddPoll from './general/polls/AddPoll';
import Polls from './general/polls/Polls';
import UpdatePoll from './general/polls/UpdatePoll';
import Documents from './general/Documents';

const ProtectedView =()=> {
  return (
    <>
       
        <Routes> 
        <Route  path="/dashboard" element={<Dashboard /> } /> 
        <Route  path="/category" element={<Category /> } /> 
        <Route  path="/staff/add" element={<AddStaff /> } /> 
        <Route  path="/staff/profile/:code" element={<UpdateStaff /> } />
        <Route  path="/staff" element={<Staff /> } />  
        <Route  path="/news" element={<News /> } /> 
        <Route  path="/news/add" element={<AddNews /> } /> 
        <Route  path="/news/edit/:code" element={<UpdateNews /> } /> 
        <Route  path="/collections" element={<Collection /> } /> 
        <Route  path="/collection/add/:code" element={<AddCollection /> } /> 
        <Route  path="/news/tags" element={<Tags /> } /> 
        <Route  path="/general/basic" element={<BasicInfo /> } /> 
        <Route  path="/general/subscribers" element={<Subscribers /> } /> 
        <Route  path="/general/advert" element={<Advert /> } /> 
        <Route  path="/general/poll/add" element={<AddPoll /> } />
        <Route  path="/documents" element={<Documents /> } /> 
        <Route  path="/general/poll/edit/:code" element={<UpdatePoll /> } /> 
        <Route  path="/general/polls" element={<Polls /> } /> 
        <Route  path="/logout" element={<Logout /> } /> 
       
        {/* <Route  path="*" element={<Error404 />} />  */}

        </Routes>
        </>
  )
}

export default ProtectedView